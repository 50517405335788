import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChildSegments } from './child-segments/child-segments';
import { HeroSearch } from './hero-search/hero-search';
import { ProposedArticles } from './proposed-articles/proposed-articles';
import { useIsApp } from '../../custom-hooks/use-is-app';
import { PageAnalytics } from '../../helpers/web-analytics/page-analytics';
import { WebAnalyticsEventContentEnum } from '../../models/enums/web-analytics.enum';
import { selectProposedArticles } from '../../redux/article/article-selectors';
import Container from '../layout/container';

const MainLanding: React.FC = () => {
    const { isMyMtsApp } = useIsApp();
    const proposedArticles = useSelector(selectProposedArticles);

    useEffect(() => {
        PageAnalytics.pageEvent('/', null, WebAnalyticsEventContentEnum.mainB2CPage);
        localStorage.removeItem('chat');
        sessionStorage.removeItem('isReturnBack');
    }, []);

    return (
        <Container>
            <HeroSearch title="для частных клиентов" />
            {isMyMtsApp && proposedArticles?.length > 0 && <ProposedArticles />}
            <ChildSegments />
        </Container>
    );
};

export default MainLanding;
