import { Article } from '../../models/article';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
} from '../../models/enums/web-analytics.enum';
import { transliterate } from '../gtm.helper';
import { GtmService, pushEventParams } from '../web-analytics.helpers';

export class ChatAnalytics extends GtmService {
    static chatEvent(eventLabel: string, article?: Article, customEventData: pushEventParams = {}) {
        let eventContent = WebAnalyticsEventContentEnum.pageProduct;
        let eventContext = null;

        if (article) {
            eventContent = WebAnalyticsEventContentEnum.article;
            eventContext = transliterate(article.name, true) ?? null;
        }

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntChat,
            eventCategory: WebAnalyticsEventCategoryEnum.chat,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            productName: this.productName,
            eventLabel: transliterate(eventLabel, true),
            eventContent: transliterate(eventContent, true),
            eventContext,
            ...customEventData,
        });
        this.log(this.chatEvent.name);
    }

    static chatEventActionShowElement(eventLabel: string, eventContent?: boolean) {
        this.chatEvent(eventLabel, undefined, {
            eventAction: WebAnalyticsEventActionEnum.elementShow,
            eventContent: eventContent ? WebAnalyticsEventContentEnum.firstMessageInChat : null,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
        });
    }
}
