import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdTokenHookInterface } from '../models/id-token-hook.interface';
import { StateModel } from '../models/state.model';
import { setIdToken, setIsIdTokenExpired } from '../redux/id-token/id-token-action';
import { selectIdTokenIsExpired } from '../redux/id-token/id-token-selectors';
import { cookiesValidate } from '../repositories/cookies-validate.repository';
import { getIdTokenHttp } from '../repositories/id-token.repository';

export const useIdToken = (): IdTokenHookInterface => {
    const dispatch = useDispatch();
    const isIdTokenExpired = useSelector<StateModel, boolean>(selectIdTokenIsExpired);

    useEffect(() => {
        cookiesValidate().then(() => requestIdToken());
    }, []);

    // update id token then it expired
    useEffect(() => {
        isIdTokenExpired && requestIdToken();
    }, [isIdTokenExpired]);

    // function for initial getting id token from profile header
    const requestIdToken = async (): Promise<string | undefined> => {
        const idToken = await getIdTokenHttp();
        dispatch(setIdToken(idToken));
        dispatch(setIsIdTokenExpired(false));

        return idToken;
    };

    return { getIdToken: requestIdToken };
};
