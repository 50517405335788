import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { getNumberType, transliterate } from '../gtm.helper';
import { GtmService } from '../web-analytics.helpers';

export class ProfileAnalytics extends GtmService {
    static authorizationSuccess(profileType: string, guid: string, tariff: string | null) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntLogin,
            eventCategory: WebAnalyticsEventCategoryEnum.authorization,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.authorizationSuccess,
            accountType: transliterate(profileType, true),
            actionGroup: WebAnalyticsActionGroupEnum.conversions,
            userAuth: 1,
            userId: guid,
            currentTariff: transliterate(tariff),
            numberType: getNumberType(profileType),
        });
        this.log(this.authorizationSuccess.name);
    }
}
