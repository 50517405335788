import api from './api/api';
import { loginMtsValidate } from '../constants/api';
import { envService } from '../helpers/env.helper';

export const cookiesValidate = async () => {
    try {
        const response = await api.get(loginMtsValidate);
        if (response.status === 200) {
            try {
                return await api.get(`${envService.getBaseUrl()}/oauth2/authorization/mts-web-sso`);
            } catch (error) {
                return null;
            }
        }
    } catch (error) {
        return null;
    }
};
