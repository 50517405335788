import { useEffect } from 'react';
import { PageAnalytics } from '../helpers/web-analytics/page-analytics';

export const useBrowserSearch = () => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const eventKey = event.key.toLowerCase() === 'f' || event.key.toLowerCase() === 'а';
            const userAgent = navigator.userAgent;
            const isMacOS = /Macintosh|Mac OS X/.test(userAgent);
            const isSearchShortcut = isMacOS
                ? event.metaKey && eventKey
                : event.ctrlKey && eventKey;

            if (isSearchShortcut) {
                PageAnalytics.browserSearchEvent();
            }

        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
};
