import { Article } from '../../models/article';
import { StateModel } from '../../models/state.model';

const getStore = (state: StateModel) => state.article;

export const selectWidgetChat = (state: StateModel) => getStore(state).currentProduct.widgetChat;
export const selectCurrentProduct = (state: StateModel) => getStore(state).currentProduct;
export const selectArticleState = (state: StateModel) => getStore(state);
export const selectCurrentArticle = (state: StateModel) => getStore(state).currentArticle;
export const selectCurrentSection = (state: StateModel) => getStore(state).currentSection;
export const selectSectionBySefUrl = (sectionSefUrl: string) => (state: StateModel) =>
    selectCurrentProduct(state).sections.find((section) => section.sefUrl === sectionSefUrl);
export const selectCachedArticles =
    (sectionId: string | number) =>
        (state: StateModel): Article[] =>
        getStore(state).cache.articles![sectionId];
export const selectArticleRating = (state: StateModel) => getStore(state).articleRating;
export const selectRatingReasons = (state: StateModel) => getStore(state).ratingReasons;
export const selectCurrentReason = (state: StateModel) => {
    const store = getStore(state);
    return store.ratingReasons?.find((reason) => reason.id === store.articleRating?.articleUselessnessReasonId);
};

export const selectIsProductHidden = (state: StateModel) => getStore(state).isProductHidden;

export const selectProposedArticles = (state: StateModel) => getStore(state).proposedArticles;
