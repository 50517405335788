import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './child-segments.styles';
import { getPartnersColumns, splitChildSegments } from './child-segments.utils';
import { useDevice } from '../../../context/device-context';
import { SegmentsEnum } from '../../../models/enums/segments.enum';
import { selectChildSegments, selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import { ChildSegmentGroups } from '../child-segment-groups/child-segment-groups';
import { SegmentProducts } from '../segment-products/segment-products';

export const ChildSegments = () => {
    const childSegments = useSelector(selectChildSegments);
    const currentSegmentCode = useSelector(selectCurrentSegmentCode);
    const { isMobile } = useDevice();
    const { isTablet } = useDevice();
    const isPartners = currentSegmentCode === SegmentsEnum.partners;

    let columnsCount = 3;
    if (isMobile) {
        columnsCount = 1;
    } else if (isTablet) {
        columnsCount = 2;
    }

    const columns = useMemo(() => {
        if (isPartners) {
            return getPartnersColumns(columnsCount, childSegments);
        }
        return splitChildSegments(columnsCount, childSegments);
    }, [childSegments, columnsCount]);

    return (
        <div>
            <ChildSegmentGroups />
            <Styled.ChildSegmentsBlock>
                {columns.map((column, index) => (
                    <div key={index}>
                        {column.map(childSegment => (
                            <SegmentProducts childSegment={childSegment} key={childSegment.id} />
                        ))}
                    </div>
                ))}
            </Styled.ChildSegmentsBlock>
        </div>
    );
};
