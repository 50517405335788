import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { mobileHide } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const StyledTelephoneModal = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.backgroundOverlayModal};
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: ${Variables.zIndexList.top};

    .wrapper-content {
        max-width: ${rem(800)};
        position: relative;

        .close {
            position: absolute;
            top: ${rem(8)};
            cursor: pointer;
            left: calc(100% + ${rem(16)});
        }
    }

    ${mobileHide};
`;

const StyledContent = styled.div`
    width: ${rem(324)};
    height: ${rem(56)};
    padding: ${rem(24)};
    background: ${({ theme }) => theme.backgroundPrimary};
    border-radius: ${Variables.borderRadiusLg};
    display: flex;
    flex-direction: column;

    .text {
        font-size: ${rem(17)};
        line-height: ${rem(24)};
        color: ${({ theme }) => theme.textSecondary};
        margin-bottom: ${rem(4)};
    }

    .telephone-number {
        display: flex;
        align-items: flex-end;
        font-family: ${Variables.fonts.fontMedium};
        font-size: ${rem(24)};
        line-height: ${rem(28)};
        color: ${({ theme }) => theme.textPrimary};
    }
`;

export { StyledContent, StyledTelephoneModal };
