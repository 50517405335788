import { css } from 'styled-components';
import {
    sidePaddingsDesktopS,
    sidePaddingsDesktopXS,
    sidePaddingsMobile,
    sidePaddingsTablet,
} from '../../components/layout/container';
import { rem } from '../../helpers/rem-converter';
// @ts-ignore
import CrossIconWhite from '../../images/icons/autofaq/cross-white.svg?next-images-ignore=true';
// @ts-ignore
import CrossIcon from '../../images/icons/autofaq/cross.svg?next-images-ignore=true';
// @ts-ignore
import SendIcon from '../../images/icons/autofaq/send.svg?next-images-ignore=true';
import { themeEnum } from '../../models/enums/theme.enum';
import {
    desktop,
    desktopL,
    desktopS,
    desktopXS,
    mobile,
    tablet,
} from '../themes/media';
import { dropShadowHigh } from '../themes/shadows';
import Variables from '../variables';

const positionStyles = css`
    ${desktopL`
        right: calc((100% - ${rem(1168)})/2) !important;
    `};
    ${desktopS`right: ${rem(sidePaddingsDesktopS)} !important`};
    ${desktopXS`right: ${rem(sidePaddingsDesktopXS)} !important`};
    ${tablet`right: ${rem(sidePaddingsTablet)} !important`};
    ${mobile`right: ${rem(sidePaddingsMobile)} !important`};

`;

const rateTextStyles = css`
    display: block;
    position: absolute;
    background-color: unset;
    bottom: ${rem(6)};
    color: ${({ theme }) => theme.textSecondary} !important;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-family: ${Variables.fonts.fontRegular};
`;

const setRateStyles = () => {
    let styles = '';
    const rates = 11;
    [...Array(rates)].forEach((_e, i) => {
        styles += `
            &:nth-child(${i + 1}) {
                &::before {content:'${i}'}
            }
        `;
    });

    return css`${styles}`;
};

export const autofaqGlobalStyles = css`
    #chat21-launcher-button {
        width: ${rem(56)};
        height: ${rem(60)};
        bottom: ${rem(-2)};
        background-color: ${({ theme }) => theme.backgroundModal};
        border-radius: 50% 50% 0 0;
        filter: ${dropShadowHigh};
        ${positionStyles};

        .svg-icon {
            display: none !important;
        }

        .chat21-button-badge {
            background-color: ${({ theme }) => theme.iconPrimary};
            color: ${({ theme }) => theme.textPrimary};
            border: ${rem(2)} solid ${({ theme }) => theme.backgroundModal};
            top: 0 !important;
            right: 0 !important;
        }
    }

    .chat21-window {
        max-height: ${rem(500)} !important;
        bottom: ${rem(70)} !important;
        background-color: ${({ theme }) => theme.backgroundModal} !important;
        ${positionStyles};
    }

    #chat21-container {
        .form_panel_field-label {
            color: ${({ theme }) => theme.textPrimary};
        }

        .form_panel_field-input {
            background-color: ${({ theme }) => theme.backgroundSecondary};
            color: ${({ theme }) => theme.textPrimary};
            border: none;
        }

        .svg-bg {
            width: inherit;
            height: inherit;

            circle {
                r: ${rem(44)};
            }

            image {
                display: none;
            }
        }

        .svg-icon {
            transform: scale(1.3) translateX(${rem(-1)});
        }

        .chat21-sheet-header-title {
            font-size: ${rem(20)};
            line-height: ${rem(24)};
            font-family: ${Variables.fonts.fontWideMedium};
        }

        .chat21-sheet-content {
            background-color: ${({ theme }) => theme.backgroundModal};
        }

        .buttons {
            time {
                display: none !important;
            }

            .button {
                &::before {
                    font-family: ${Variables.fonts.fontRegular};
                    font-size: ${rem(14)};
                    line-height: ${rem(20)};
                }

                ${setRateStyles()};

                span {
                    span {
                        display: none;
                    }
                }
            }
        }

        .base_receive.buttons {
            position: relative;
            flex-flow: unset !important;
            padding: ${rem(4)} ${rem(10)} !important;
            justify-content: space-between;

            button {
                overflow: unset;
                height: ${rem(32)} !important;
                flex: 1;
                margin: ${rem(20)} ${rem(4)} ${rem(30)} 0;
                color: ${({ theme }) => theme.textPrimary} !important;
                background-color: ${({ theme }) => theme.backgroundSecondary} !important;
                box-shadow: unset;

                &:last-child {
                    margin: ${rem(20)} 0 ${rem(30)};
                }

                ${desktop`
                    &[disabled] {
                        &:hover {
                            background-color: ${({ theme }) => theme.backgroundSecondary} !important;
                            color: unset !important;
                        }
                    }

                    &:hover {
                        background-color: ${({ theme }) => theme.controlSecondaryActive} !important;
                        color: ${({ theme }) => theme.textInverted} !important;
                    }
                `};

                &:first-child {
                    &::after {
                        content: "Точно нет";
                        left: ${rem(8)};
                        ${rateTextStyles};
                    }
                }

                &:last-child {
                    &::after {
                        content: "Точно да";
                        right: ${rem(10)};
                        ${rateTextStyles};
                    }
                }
            }
        }

        .chat21-sheet-header {
            color: ${({ theme }) => theme.textHeadline} !important;
            background-color: ${({ theme }) => theme.backgroundModal};
            box-shadow: none;
        }

        .chat21-sheet-header-close-button {
            svg path {
                fill: ${({ theme }) => theme.textHeadline};
            }
        }

        .chat21-header-modal-select {
            color: ${({ theme }) => theme.textSecondary};
        }
    }

    #chat21-launcher-button-placeholder {
        &::before {
            content: "";
            display: block;
            background-image: url("https://s3.cms.mts.ru/cms-files/chat-icon.svg");
            width: ${rem(20)};
            height: ${rem(20)};
            transform: translate(${rem(18)}, ${rem(48)});
        }
    }

    #chat21-conversations {
        .message_sender_fullname {
            font-family: ${Variables.fonts.fontMedium};
            color: ${({ theme }) => theme.textPrimary};
            font-size: ${rem(14)};
            line-height: ${rem(20)};
        }

        .form_panel {
            background-color: ${({ theme }) => theme.backgroundModal} !important;
        }

        .messages {
            padding: ${rem(12)} ${rem(16)};
            p {
                font-family: ${Variables.fonts.fontRegular} !important;
                font-size: ${rem(17)} !important;
                line-height: ${rem(24)} !important;
            }
        }

        .form_panel {
            border-color: unset;
        }

        .time {
            font-family: ${Variables.fonts.fontRegular};
        }
    }

    #chat21-footer {
        bottom: ${rem(18)};
        box-shadow: none;
        border-top: 1px solid ${({ theme }) => theme.backgroundStroke};

        textarea {
            font-family: ${Variables.fonts.fontRegular};
            font-size: ${rem(17)};
        }
    }

    #chat21-end-footer {
        display: none;
    }

    #chat21-start-upload-doc {
        cursor: pointer;
        top: ${rem(12)};
        left: ${rem(-7)};
        background-image: url(${({ theme }) => theme.themeName === themeEnum.dark ? CrossIconWhite : CrossIcon});
    }

    #chat21-button-send {
        background-image: url(${SendIcon});
        margin: 0;
        padding: 0;
        transform: scale(2);
        right: ${rem(19)};
        top: ${rem(12)};
        cursor: pointer;
    }

    .f21textarea {
        background-color: ${({ theme }) => theme.backgroundModal} !important;
        border-radius: 0 !important;
        color: ${({ theme }) => theme.textPrimary} !important;
        padding: ${rem(10)} ${rem(60)} ${rem(10)} ${rem(47)} !important;

        &::placeholder {
            color: ${({ theme }) => theme.textSecondary};
        }
    }
`;

export const autofaqAppGlobalStyles = css`
    #chat21-container {
        display: none !important;
    }
`;
