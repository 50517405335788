import React from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './proposed-articles.styles';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { ProposedArticlesAnalytics } from '../../../helpers/web-analytics/proposed-articles-analytics';
import { selectProposedArticles } from '../../../redux/article/article-selectors';
import { ProposedArticle } from '../../../redux/article/article-types';

export const ProposedArticles: React.FC = () => {
    const proposedArticles = useSelector(selectProposedArticles);
    const router = useAppRouter();

    const goToProposedArticle = (event: React.MouseEvent, article: ProposedArticle) => {
        event?.stopPropagation();
        event?.preventDefault();

        ProposedArticlesAnalytics.goToProposedArticle(article?.name);
        router.push(article?.link || '');
    };

    return (
        <Styled.ProposedArticlesWrapper>
            <Styled.ProposedArticles>
                {proposedArticles.map((article) => (
                    <Styled.ProposedArticleLink
                        href={article.link}
                        key={article.name}
                        onClick={(event) => goToProposedArticle(event, article)}
                    >{article.name}</Styled.ProposedArticleLink>
                ))}
            </Styled.ProposedArticles>
        </Styled.ProposedArticlesWrapper>
    );
};
