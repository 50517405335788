import React, {
    FC,
    ReactNode,
    useContext,
    useState,
} from 'react';
import { ThemeProvider } from 'styled-components';
import { themeCookie } from '../constants/cookies';
import { setCookie } from '../helpers/cookies';
import { darkTheme } from '../styles/themes/dark';
import { lightTheme } from '../styles/themes/light';
import { Themeinterface } from '../styles/themes/theme.type';

interface ThemeContext {
    theme: Themeinterface;
    toggleTheme: (value: string) => void;
}

const ThemeContext = React.createContext<ThemeContext | null>(null);

export const useTheme = (): ThemeContext => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeContextProvider');
    }
    return context;
};

interface ThemeContextProvider {
    value: Themeinterface;
    children: ReactNode;
}

export const ThemeContextProvider: FC<ThemeContextProvider> = ({ value = lightTheme, children }) => {
    const [theme, setTheme] = useState(value);

    const toggleTheme = (value: string) => {
        setTheme(value === 'dark' ? darkTheme : lightTheme);
        setCookie(themeCookie, value);
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};
