import React, {FC, SVGProps} from 'react';

export const Telegram: FC = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_15_5813)">
            <path fillRule="evenodd" clipRule="evenodd" d="M44 22C44 34.15 34.15 44 22 44C9.85 44 0 34.15 0 22C0 9.85 9.85 0 22 0C34.15 0 44 9.85 44 22ZM33.4 10.116C32.9358 10.0846 32.4706 10.1599 32.04 10.336L7.29 20.563C6.802 20.73 6.361 21.013 6.006 21.388C5.91079 21.4956 5.84428 21.6255 5.8126 21.7656C5.78093 21.9058 5.78513 22.0517 5.82482 22.1898C5.8645 22.3278 5.93838 22.4537 6.03962 22.5556C6.14086 22.6576 6.26619 22.7324 6.404 22.773C7.747 23.227 9.095 23.663 10.447 24.09C11.3507 24.4336 12.3167 24.5832 13.282 24.529C13.7271 24.5047 14.1592 24.3707 14.54 24.139C15.083 23.775 15.926 23.205 16.915 22.537C20.016 20.44 24.546 17.378 25.683 16.665C26.1221 16.3659 26.6057 16.1381 27.116 15.99C27.447 15.923 27.555 16.145 27.464 16.471C27.2875 16.9401 27.0127 17.3659 26.658 17.72C24.436 19.9193 22.1767 22.0806 19.881 24.203C19.441 24.569 19.029 24.966 18.646 25.39C18.4329 25.6191 18.3146 25.9204 18.3148 26.2332C18.315 26.546 18.4337 26.8472 18.647 27.076C18.7389 27.1898 18.8425 27.2937 18.956 27.386C19.951 28.207 21.008 28.946 22.076 29.666C23.5549 30.6619 25.0383 31.6512 26.526 32.634L26.621 32.696C27.371 33.221 28.211 33.6 29.101 33.816C29.5236 33.9466 29.9808 33.9045 30.3724 33.6989C30.7641 33.4933 31.0584 33.141 31.191 32.719C31.311 32.405 31.394 32.078 31.436 31.744C31.476 31.49 31.599 30.7 31.776 29.58C32.516 24.867 34.173 14.31 34.336 13.173C34.453 12.555 34.51 11.927 34.508 11.298C34.5156 10.9949 34.4036 10.7011 34.1963 10.4799C33.989 10.2587 33.7029 10.128 33.4 10.116Z" fill="#0088CC"/>
            <path d="M33.4 10.116C32.9358 10.0846 32.4706 10.1599 32.04 10.336L7.29 20.563C6.802 20.73 6.361 21.013 6.006 21.388C5.91079 21.4956 5.84428 21.6255 5.8126 21.7656C5.78093 21.9058 5.78513 22.0517 5.82482 22.1898C5.8645 22.3278 5.93838 22.4537 6.03962 22.5556C6.14086 22.6576 6.26619 22.7324 6.404 22.773C7.747 23.227 9.095 23.663 10.447 24.09C11.3507 24.4336 12.3167 24.5832 13.282 24.529C13.7271 24.5047 14.1592 24.3707 14.54 24.139C15.083 23.775 15.926 23.205 16.915 22.537C20.016 20.44 24.546 17.378 25.683 16.665C26.1221 16.3659 26.6057 16.1381 27.116 15.99C27.447 15.923 27.555 16.145 27.464 16.471C27.2875 16.9401 27.0127 17.3659 26.658 17.72C24.436 19.9193 22.1767 22.0806 19.881 24.203C19.441 24.569 19.029 24.966 18.646 25.39C18.4329 25.6191 18.3146 25.9204 18.3148 26.2332C18.315 26.546 18.4337 26.8472 18.647 27.076C18.7389 27.1898 18.8425 27.2937 18.956 27.386C19.951 28.207 21.008 28.946 22.076 29.666C23.5549 30.6619 25.0383 31.6512 26.526 32.634L26.621 32.696C27.371 33.221 28.211 33.6 29.101 33.816C29.5236 33.9466 29.9808 33.9045 30.3724 33.6989C30.7641 33.4933 31.0584 33.141 31.191 32.719C31.311 32.405 31.394 32.078 31.436 31.744C31.476 31.49 31.599 30.7 31.776 29.58C32.516 24.867 34.173 14.31 34.336 13.173C34.453 12.555 34.51 11.927 34.508 11.298C34.5156 10.9949 34.4036 10.7011 34.1963 10.4799C33.989 10.2587 33.7029 10.128 33.4 10.116Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_15_5813">
                <rect width="44" height="44" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
