import React, { MouseEvent, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import * as Styled from './child-segment-link.styles';
import { getProductLinkAttrName } from './child-segment-link.utils';
import { useDevice } from '../../../context/device-context';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { makeLastSpaceNonBreaking } from '../../../helpers/make-last-space-non-breaking.helper';
import { MainPageAnalytics } from '../../../helpers/web-analytics/main-page-analytics';
// @ts-ignore
import LogoDefault from '../../../images/icons/logo-default.svg?next-images-ignore=true';
import { ShortProduct } from '../../../models/product';
import { setMainPageGroupLoading, stopMainPageGroupLoading } from '../../../redux/ui/ui-actions';
import { selectMainPageGroupLoading } from '../../../redux/ui/ui-selectors';
import { AppLink } from '../../app-link';
import { ArrowRightIcon } from '../../svg-components/main/arrow-right';
import { Icon } from '../icon/icon';
import { LoaderCircle } from '../loader-circle/loader-circle';

interface ProductLinkProps {
    product: ShortProduct;
    segmentTitle?: string;
    externalLink?: string;
    groupIndex?: number;
    isGroupVariant?: boolean;
}

const ChildSegmentLink: React.FC<ProductLinkProps> = ({
    segmentTitle,
    product,
    externalLink,
    groupIndex,
    isGroupVariant,
}) => {
    const dispatch = useDispatch();
    const router = useAppRouter();
    const { isMobileOrTablet } = useDevice();
    const themeContext = useContext(ThemeContext);

    const handleGtmEvent = useCallback(() => {
        MainPageAnalytics.productNameClick(product.name, segmentTitle);
    }, [product.name, segmentTitle]);

    const loadingId = useSelector(selectMainPageGroupLoading);
    const isDefaultLogo = !product?.logo?.showLink;
    const isSvg = isDefaultLogo ? true : product.logo?.name.includes('.svg');
    const logoSrc = isDefaultLogo ? LogoDefault : product.logo.showLink;
    const isLoading = !!loadingId;

    const linkClickHandler = async (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        handleGtmEvent();

        if (!externalLink) {
            dispatch(setMainPageGroupLoading(product.id!));
            await router.push('/[product]', product.sefUrl);
            dispatch(stopMainPageGroupLoading());
        }
    };

    const logo = (size: number) => isSvg ? <Icon src={logoSrc} height={size} width={size} /> : <img src={logoSrc} alt="" />;

    const contentComponent = (
        <>
            {isGroupVariant ? <Styled.GroupIconWrapper>{logo(24)}</Styled.GroupIconWrapper> : <Styled.IconWrapper>{logo(44)}</Styled.IconWrapper>}
            <Styled.LinkTextBlock>
                <Styled.LinkTitle>{makeLastSpaceNonBreaking(product.name)}</Styled.LinkTitle>
                {!isGroupVariant && <Styled.LinkDescription>{product.description}</Styled.LinkDescription>}
            </Styled.LinkTextBlock>
            {isGroupVariant && isMobileOrTablet && <ArrowRightIcon />}
        </>
    );

    return externalLink ? (
        <Styled.Item
            onClick={handleGtmEvent}
            href={externalLink}
            target="_blank"
            rel="noopener noreferrer"
            data-test={getProductLinkAttrName(groupIndex)}
            isLoading={isLoading}
        >
            {contentComponent}
        </Styled.Item>
    ) : (
        <AppLink href={'/[product]'} as={product.sefUrl} passHref>
            <Styled.Container>
                <Styled.Item
                    onClick={linkClickHandler}
                    data-test={getProductLinkAttrName(groupIndex)}
                    isLoading={isLoading}
                >
                    {contentComponent}
                </Styled.Item>
                {loadingId === product.id && <LoaderCircle type={themeContext.loaderColor} centered />}
            </Styled.Container>
        </AppLink>
    );
};

export default ChildSegmentLink;
