import React, { useEffect, useState } from 'react';
// @ts-ignore
import toNext from '@prp/svelte-adapter/next';
// @ts-ignore
import { EcosystemHeader as EcosystemHeaderFromSvelte, EcosystemHeaderSsr as EcosystemHeaderFromSvelteSsr } from '@pxp/ecosystem-header-b2c';
import { useDispatch, useSelector } from 'react-redux';
import { getLogInOutEndpoints, navItems } from './header.constants';
import {
    adaptiveStyles,
    HeaderContainer,
    HeaderSpacer,
    ModalWrapper,
} from './header.styles';
import { ThemeSwitchedValue } from './header.types';
import {
    isAuth,
    getParentSegmentUrl,
    removeIsAuth,
    setIsAuth,
} from './header.utils';
import { useDevice } from '../../../context/device-context';
import { useTheme } from '../../../context/theme-context';
import { useNoScroll } from '../../../custom-hooks/no-scroll';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { useHideOnScrollHeader } from '../../../custom-hooks/use-hide-on-scroll-header.hook';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { useHeader404EnvsHack } from '../../../custom-hooks/useHeader404EnvsHack.hook';
import { envService } from '../../../helpers/env.helper';
import { getGeolocationCookieData, getRequestGeoCookie, setRequestGeolocationCookie } from '../../../helpers/geolocation';
import { GeoAnalytics } from '../../../helpers/web-analytics/geo-analytics';
import { ProfileAnalytics } from '../../../helpers/web-analytics/profile-analytics';
import { WidgetChatInterface } from '../../../models/chat-simple';
import { CurrentUserModel } from '../../../models/current-user.model';
import { ChannelTypeEnum } from '../../../models/enums/chat-simple.enum';
import { ColorThemeEnum } from '../../../models/enums/color-theme.enum';
import { WebAnalyticsActionGroupEnum, WebAnalyticsEventActionEnum, WebAnalyticsEventLabelsEnum } from '../../../models/enums/web-analytics.enum';
import { StateModel } from '../../../models/state.model';
import { selectWidgetChat } from '../../../redux/article/article-selectors';
import { selectCurrentUser } from '../../../redux/current-user/current-user-selectors';
import { selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import { setGeoModalOpened } from '../../../redux/ui/ui-actions';
import { selectGeoModalOpened } from '../../../redux/ui/ui-selectors';
import { GeolocationConfirm } from '../../containers/geolocation-confirm-modal/geolocation-confirm';
import { GeolocationConfirmContainer } from '../../containers/geolocation-confirm-modal/geolocation-confirm.styles';
import { HeaderTopControls } from '../../containers/header-top-controls/header-top-controls';
import { HeaderTopControlsSpacer } from '../../containers/header-top-controls-spacer/header-top-controls-spacer';
import { SegmentsMenuMobile } from '../../containers/segments-menu/segments-menu-mobile';
import { TelephoneModal } from '../../containers/telephone-modal/telephone-modal';
import Container from '../../layout/container';

const EcosystemHeaderNext = toNext(EcosystemHeaderFromSvelte, EcosystemHeaderFromSvelteSsr, {}, 'div');

const Header: React.FC<{ theme: ColorThemeEnum }> = ({ theme }) => {
    const router = useAppRouter();
    const dispatch = useDispatch();
    const { isDesktop } = useDevice();
    const { isMobileOrTablet } = useDevice();

    const isVisibleModalTelephone = useSelector<StateModel, boolean>((state) => state.chat.isVisibleModalTelephone);
    const widgetChat = useSelector<StateModel, WidgetChatInterface>(selectWidgetChat);
    const currentUser = useSelector<StateModel, CurrentUserModel>(selectCurrentUser);
    const geoModalOpened = useSelector(selectGeoModalOpened);
    const segmentCode = useSelector(selectCurrentSegmentCode);

    const [geolocationText, setGeolocationText] = useState<string | null>('');
    const [isHidden, setIsHidden] = useState(router.pathname === '/404');
    const [isModalConfirmGeoOpened, setIsModalConfirmGeoOpened] = useState(false);

    const geolocation = getGeolocationCookieData();
    const { isApp } = useIsApp();
    const envsEnabled = useHeader404EnvsHack();

    const telephoneChannel = widgetChat?.messengerChannels?.find((elem) => elem.type === ChannelTypeEnum.phone);
    const contactsPage = router.asPath.includes('contacts');

    useEffect(() => {
        if (!localStorage?.getItem(isAuth) && currentUser.id) {
            setIsAuth();
            if (currentUser.id) {
                const { accountType: profileType, id: guid, currentTariff: tariff } = currentUser;

                if (guid) {
                    ProfileAnalytics.authorizationSuccess(profileType || '', guid || '', tariff || null);
                }
            }
        }
    }, [currentUser.id]);

    useNoScroll(geoModalOpened);
    useNoScroll(isVisibleModalTelephone);

    useEffect(() => {
        if (geolocation) {
            setGeolocationText(geolocation.name);
        }
        setIsModalConfirmGeoOpened(getRequestGeoCookie());

        // хак, решающий баг nextjs на странице 404, когда query на сервере всегда пустой. Отключаем визуально шапку на серверном рендеринге
        const timeoutHidden = setTimeout(() => {
            setIsHidden(false);
        });

        return () => {
            clearTimeout(timeoutHidden);
        };
    }, []);

    const { isVisible, isFixed, headerRef } = useHideOnScrollHeader();
    const isShowHeader = isFixed || isDesktop || isApp;

    const getActiveTab = () => {
        const path = router.query.routes;
        const item = navItems.find((item) => path?.includes(item.href.substring(1)));
        const isAtHome = !path || !item;
        return isAtHome ? navItems[0] : item;
    };

    const handleCloseModal = () => {
        GeoAnalytics.geoEvent(WebAnalyticsEventActionEnum.buttonClick, WebAnalyticsEventLabelsEnum.yes, WebAnalyticsActionGroupEnum.interactions, geolocationText);
        setRequestGeolocationCookie(false);
        setIsModalConfirmGeoOpened(false);
    };

    const handleModalChangeGeolocation = (value: boolean) => {
        setRequestGeolocationCookie(false);
        setIsModalConfirmGeoOpened(false);
        dispatch(setGeoModalOpened(value));
    };

    const handleProfileChanged = () => {
        location.reload();
    };

    const { toggleTheme } = useTheme();

    const handleThemeSwitched = (value: ThemeSwitchedValue) => {
        toggleTheme(value.detail);
    };

    return isHidden || !envsEnabled ? null : (
        <>
            <HeaderContainer isVisible={isVisible} isFixed={isFixed} isShowHeader={isShowHeader} id="header-container"
                ref={headerRef}>
                {
                    isModalConfirmGeoOpened && !isApp && !isMobileOrTablet && <GeolocationConfirmContainer>
                        <ModalWrapper>
                            <GeolocationConfirm
                                onConfirm={handleCloseModal}
                                cityName={geolocationText}
                                onChangeRequest={() => handleModalChangeGeolocation(true)}
                            />
                        </ModalWrapper>
                    </GeolocationConfirmContainer>
                }
                {!contactsPage &&
                    <Container>
                        <HeaderTopControls cityName={geolocationText}
                            onCityClick={() => handleModalChangeGeolocation(true)}/>
                    </Container>
                }
                {!isApp && <EcosystemHeaderNext
                    id="header-id"
                    isB2C={true}
                    authUrls={getLogInOutEndpoints()}
                    logoText="Поддержка"
                    env={envService.getHeaderStand()}
                    selectedNavItem={getActiveTab()}
                    navItems={navItems}
                    // возникал баг с 404 при редиректе несколькими пользователями, поэтому false
                    isLogoNavigate={false}
                    homeLink={getParentSegmentUrl(segmentCode || '/')}
                    onBusinessProfileChanged={handleProfileChanged}
                    contentStyles={adaptiveStyles}
                    disableRedirectToLogin={true}
                    thirdLevel={false}
                    geoText={geolocationText}
                    enableGeo={false}
                    showGeoLine={false}
                    showGeoConfirmLine={false}
                    onTempGeoButtonClicked={() => handleModalChangeGeolocation(true)}
                    onTempGeoConfirmLineChangeRequested={() => handleModalChangeGeolocation(true)}
                    onTempGeoConfirmLineConfirmed={() => setRequestGeolocationCookie(false)}
                    onProfileUnauthorized={() => removeIsAuth()}
                    theme={theme}
                    widgets={{ themeToggleSwitch: theme }}
                    onThemeSwitched={handleThemeSwitched}
                />}
                <SegmentsMenuMobile/>
            </HeaderContainer>
            {/* spacer'ы для учета отступов сверху т.к. шапка position: fixed */}
            <>
                {isShowHeader &&
                        <>
                            {!isApp && !contactsPage && <HeaderSpacer/>}
                            <HeaderTopControlsSpacer/>
                        </>
                }
            </>
            <TelephoneModal isOpen={isVisibleModalTelephone} telephoneNumber={telephoneChannel?.link}/>
        </>
    );
};

export default Header;
