import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { scrollbar } from '../../../styles/shared-css/custom-scroll';
import { mobile } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

export const StyledAccordion = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: ${Variables.zIndexList.level4};
    background-color: ${({ theme }) => theme.backgroundPrimary};

    .input-wrapper {
        position: relative;
        margin: 0 ${rem(40)};

        .search-input {
            z-index: ${Variables.zIndexList.level1};
            margin-bottom: ${rem(28)};
        }
    }

    .accordion {
        width: 100%;
        max-height: calc(100vh - ${rem(154)});
        height: 100%;
        overflow-y: auto;
        ${scrollbar};
    }

    .accordion-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
        transition: background 200ms ease-out;
        position: relative;

        .title {
            display: flex;
            justify-content: space-between;
        }

        p {
            display: inline-block;
            font-size: ${rem(17)};
            line-height: ${rem(24)};
            font-family: ${Variables.fonts.fontMedium};
            text-align: left;
            margin-right: auto;
        }

        .icon {
            width: ${rem(24)};
            display: inline-block;
            text-align: center;
            margin-right: ${rem(8)};

            svg {
                margin: 0 auto;
            }
        }

        .triangle {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            margin: 0;

            path {
                fill: ${({ theme }) => theme.iconPrimary};
            }
        }
    }

    .accordion-input {
        width: 0;
        height: 0;
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
    }

    .accordion-children {
        display: none;
        margin: ${rem(12)} 0;

        li {
            display: flex;
            height: ${rem(44)};
            align-items: center;
            padding-left: ${rem(8)};
            font-size: ${rem(14)};
            color: ${({ theme }) => theme.textPrimary};
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            &.activeCity {
                animation: highlight 800ms forwards;
                animation-delay: 1200ms;
            }
        }
    }

    .active {
        display: block;
        margin-bottom: ${rem(22)};
    }

    .active svg {
        transform: rotate(180deg);
    }

    @keyframes highlight {
        0% {
            background-color: transparent;
        }

        50% {
            background-color: ${Variables.colors.greyHeatherColor};
        }

        100% {
            background-color: transparent;
        }
    }

    ${mobile`
        .input-wrapper {
            margin: 0 ${rem(16)};
        }
    `};
`;

export const AccordionItem = styled.div`
    margin-bottom: ${rem(32)};
    margin: 0 ${rem(46)} ${rem(20)} ${rem(40)};
`;
