import { GtmService } from '../web-analytics.helpers';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum
} from '../../models/enums/web-analytics.enum';
import { transliterate } from '../gtm.helper';

export class ProposedArticlesAnalytics extends GtmService {
    static goToProposedArticle(articleName: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntSuggest,
            eventCategory: WebAnalyticsEventCategoryEnum.suggest,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.topQuestion,
            eventContent: null,
            eventContext: transliterate(articleName, true),
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null
        });

        this.log(this.goToProposedArticle.name);
    }
}
