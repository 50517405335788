import { WebAnalyticsEventActionEnum, WebAnalyticsEventCategoryEnum, WebAnalyticsEventEnum } from '../../models/enums/web-analytics.enum';
import { transliterate } from '../gtm.helper';
import { GtmService } from '../web-analytics.helpers';

export class MainPageAnalytics extends GtmService {
    static productNameClick(_name: string, _segmentTitle?: string) {
        const name = transliterate(_name, true);
        const segmentTitle = transliterate(_segmentTitle, true);

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntMain,
            eventCategory: WebAnalyticsEventCategoryEnum.home,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: name,
            screenName: '/',
            eventContent: segmentTitle,
            productName: name,
        });
        this.log(this.productNameClick.name);
    }
}
