import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import * as Styled from './footer.styles';
import { useDevice } from '../../../context/device-context';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import Container from '../../layout/container';

const Footer: FC = (): ReactElement => {
    const { isMobile } = useDevice();
    const [date, setDate] = useState<number>(2025);
    const { isApp } = useIsApp();

    useEffect(() => {
        setDate(new Date().getFullYear());
    }, []);

    return (
        <Styled.FooterWrapper id="footer-id">
            {!isApp && (
                <Container>
                    {!isApp && (
                        <Styled.Footer id="footer-id">
                            {!isMobile && (
                                <Styled.FooterText>© {date} ПАО «МТС» Все права защищены</Styled.FooterText>
                            )}
                            {isMobile && (
                                <Styled.FooterText>© {date} ПАО «МТС»<br/>Все права защищены. 18+</Styled.FooterText>
                            )}
                            {!isMobile && (
                                <Styled.FooterTextRight>18+</Styled.FooterTextRight>
                            )}
                        </Styled.Footer>
                    )}
                </Container>
            )}
        </Styled.FooterWrapper>
    );
};

export default Footer;
