import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { catchEmptyData } from './middleware/catch-empty-data';
import {
    AfterMiddlewareType,
    ApiRequestMethodType,
    ApiRequestType,
    BeforeMiddlewareType,
    SettingsType,
} from './types';
import { appendToCookieString } from '../../helpers/cookies';
import { isServer } from '../../helpers/isServer';
import { logThis } from '../../helpers/log-this';
import { logLevels } from '../../models/types/logger.type';

const beforeMiddlewares: BeforeMiddlewareType[] = [];
const afterMiddlewares: AfterMiddlewareType[] = [catchEmptyData];

const filterCookies = (cookieString: string = '', allowedCookies: Record<string, boolean> = {}): string => {
    if (!cookieString) {
        return '';
    }

    return cookieString
        .split(';')
        .map((cookie) => cookie.trim())
        .filter((cookie) => {
            const [name] = cookie.split('=').map((str) => str.trim());
            return allowedCookies[name] || name.toLowerCase().includes('support');
        })
        .join('; ');
};

const prepareRequestConfig = (url: string, cookies?: string): AxiosRequestConfig => {
    const isNodeCookie = isServer ? 'node=true;' : '';
    const allCookies = isServer ? cookies : document.cookie;

    // Список разрешённых кук
    const allowedCookies: Record<string, boolean> = { 'MTSWebSSO': true };

    const filteredCookies = filterCookies(allCookies, allowedCookies);

    const cookieHeader = url.includes('support') ? isNodeCookie + filteredCookies : isNodeCookie + allCookies;

    return {
        headers: {
            ...api.headers,
            Cookie: cookieHeader,
        },
        withCredentials: true,
    };
};

const runMiddlewares = (
    initialData: SettingsType['data'] | AxiosResponse,
    middlewares: BeforeMiddlewareType[] | AfterMiddlewareType[],
) => {
    let currentData = initialData;

    middlewares.forEach((func) => {
        const middlewareResult = func(currentData);
        middlewareResult && (currentData = middlewareResult);
    });

    return currentData;
};

const request: ApiRequestType = async (method, url, _data, _settings) => {
    const settings = prepareRequestConfig(url, _settings?.cookies);
    const data = runMiddlewares(_data, beforeMiddlewares);

    const axiosParams = {
        method,
        url,
        [method.toLowerCase() === 'get' ? 'params' : 'data']: data,
        ...settings,
    };

    try {
        const res = await axios(axiosParams);

        return runMiddlewares(res, afterMiddlewares);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ошибка запроса:', url, e);

        logThis(url, {
            level: logLevels.error,
            label: `Ошибка запроса: ${e.message || ''}`,
            data: {
                method,
                error: e,
            },
        });

        throw e;
    }
};

const get: ApiRequestMethodType = (url: string, data, settings) => {
    return request('get', url, data, settings);
};

const post: ApiRequestMethodType = (url: string, data, settings) => {
    return request('post', url, data, settings);
};

const put: ApiRequestMethodType = (url: string, data, settings) => {
    return request('put', url, data, settings);
};

const patch: ApiRequestMethodType = (url: string, data, settings) => {
    return request('patch', url, data, settings);
};

const deleteRequest: ApiRequestMethodType = (url: string, params, settings) => {
    return request('delete', url, undefined, { params, ...settings });
};

// установить всю строку cookie целиком
const setCookieString = (cookieString: string) => {
    api.headers = {
        ...api.headers,
        Cookie: cookieString,
    };
};

// добавить дефолтный header для всех запросов
const addHeader = (headers: AxiosRequestConfig['headers']) => {
    api.headers = {
        ...api.headers,
        ...headers,
    };
};

// добавить дефолтную cookie для всех запросов
const addCookie = (cookieName: string, cookieValue: string) => {
    api.removeCookie(cookieName);
    const newCookieString = appendToCookieString(api.headers && api.headers.Cookie, cookieName, cookieValue);

    api.headers = {
        ...api.headers,
        Cookie: newCookieString,
    };
};

const removeCookie = (cookieName: string) => {
    api.headers = {
        ...api.headers,
        Cookie:
            api.headers?.Cookie.replace(new RegExp(` {0,}${cookieName}=[^;]+;{0,1} {0,}`, 'ig'), '').replace(
                /;$/,
                '',
            ) || '',
    };
};

const api = {
    settings: {},
    headers: { 'Content-Type': 'application/json' } as AxiosRequestConfig['headers'],

    get,
    post,
    patch,
    put,
    delete: deleteRequest,
    request,

    setCookieString,
    addHeader,
    addCookie,
    removeCookie,
};

export default api;
