const loginMtsApi = 'https://login.mts.ru/amserver/api';
const loginMtsValidate = 'https://login.mts.ru/amserver/oauth2/sso/validate';
const geolocationApi = 'https://geolocation.qsupport.mts.ru/api/getlocation/?type=json';
const egisApi = 'https://api.egis.mts.ru';

export {
    loginMtsApi,
    loginMtsValidate,
    geolocationApi,
    egisApi,
};
