import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useIsApp } from './use-is-app';
import { useDevice } from '../context/device-context';
import { RouterHelper } from '../helpers/router-helper';

export const useHideOnScrollHeader = () => {
    const router = useRouter();
    const anchor = RouterHelper.getAnchorFromUrl(router.asPath);
    const { isDesktop } = useDevice();
    const { isApp } = useIsApp();

    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [isFixed, setIsFixed] = useState<boolean>(false);
    const [isFixedScroll, setFixedScroll] = useState(false);

    const headerRef = useRef<HTMLDivElement>(null);
    const lastScrollYRef = useRef<number>(0);
    const scrollUpDistanceRef = useRef<number>(0);

    const visibilityThreshold = 100;

    const setFixedHeader = () => {
        setIsFixed(true);
        setIsVisible(true);
        setFixedScroll(true);
        lastScrollYRef.current = window.scrollY;
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (!headerRef.current) {
            return;
        }

        const headerHeight = headerRef.current.offsetHeight;

        if (currentScrollY > headerHeight && currentScrollY < lastScrollYRef.current && !isFixed) {
            setIsFixed(true);
        } else if (currentScrollY <= 0 && isFixed) {
            setIsFixed(false);
            scrollUpDistanceRef.current = 0;
        } else if (currentScrollY > headerHeight && !anchor) {
            setIsVisible(false);
        } else if (lastScrollYRef.current - currentScrollY > visibilityThreshold) {
            setIsVisible(true);
        } else if (currentScrollY <= headerHeight) {
            setIsFixed(false);
            setIsVisible(true);
        }

        if (isFixed) {
            handleScrollUpDown(currentScrollY);
        }

        lastScrollYRef.current = currentScrollY;
    };

    const handleScrollUpDown = (currentScrollY: number) => {
        const scrollDiff = lastScrollYRef.current - currentScrollY;

        if (currentScrollY < lastScrollYRef.current) {
            scrollUpDistanceRef.current += scrollDiff;
            if (scrollUpDistanceRef.current >= visibilityThreshold) {
                setIsVisible(true);
            }
        } else if (currentScrollY > lastScrollYRef.current) {
            setIsVisible(false);
            scrollUpDistanceRef.current = 0;
        }
    };

    useEffect(() => {
        if (!isFixedScroll) {
            return;
        }
        // если статья содержит anchor, делаем небольшую задержку, чтобы шапка была видна и после уже навешиваем кастомный скролл
        const waitForAnchor = setTimeout(() => {
            window.addEventListener('scroll', handleScroll);
        }, 100);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(waitForAnchor);
        };
    }, [isFixedScroll]);

    useEffect(() => {
        if (isDesktop || isApp) {
            return;
        }

        if (anchor) {
            setFixedHeader();
        } else {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (!anchor) {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isFixed, isDesktop, isApp]);

    return { isVisible, isFixed, headerRef };
};
