import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';

export const ProposedArticlesWrapper = styled.div`
    margin-bottom: ${rem(24)};
`;

export const ProposedArticles = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(-4)};
`;

export const ProposedArticleLink = styled.a`
    padding: ${rem(12)};
    border-radius: ${rem(16)};
    font-size: ${rem(14)};
    line-height: 1.4;
    text-decoration: none;
    flex-grow: 1;
    flex-basis: 40%;
    margin: ${rem(4)};
    background-color: ${({theme}) => theme.backgroundSecondary};
    color: ${({theme}) => theme.textPrimary};
`;
