import styled from 'styled-components';
import { segmentsLineHeight } from '../../../constants/header';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';

export const Wrapper = styled.div<{
    withMenuOnMobile: boolean;
    isApp: boolean;
}>`
    height: ${rem(32)};
    ${media(tablet, mobile)`
        height: ${({ withMenuOnMobile, isApp }) => {
        let totalHeight = segmentsLineHeight;
        
        if (!isApp && withMenuOnMobile) {
            totalHeight += 32;
        }
        return rem(totalHeight);
    }};
    `};
`;
