import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import {
    desktopHide,
    mediaHide,
    mobileHide,
    tabletHide,
} from '../../../styles/themes/media';
import { zIndex } from '../../../styles/tokens/zIndex';
import Variables from '../../../styles/variables';

const messengerWidth = 56;

export const StyledWrapper = styled.div`
    position: relative;
`;

export const StyledChatSimple = styled.div<{ isOpened: boolean }>`
    position: absolute;
    right: 0;
    width: ${rem(messengerWidth)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledChatToggle = styled.div`
    position: fixed;
    padding: ${rem(8)} ${rem(6)};
    box-sizing: border-box;
    width: ${rem(messengerWidth)};
    height: ${rem(60)};
    cursor: pointer;
    bottom: -2px;
    background-color: ${({ theme }) => theme.backgroundModal};
    filter: ${Variables.filter.balloon};
    border-radius: 50% 50% 0 0;
    user-select: none;
    z-index: ${Variables.zIndexList.level4};

    &.active {
        background-color: ${Variables.colors.mainRed};
    }
`;

export const StyledChatRound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(44)};
    height: ${rem(44)};
    border-radius: 50%;
    background-color: ${Variables.colors.mainRed};

    &.active {
        background-color: ${({ theme }) => theme.backgroundInverted};
    }
`;

export const StyledCloseIcon = styled.div`
    path {
        fill: ${({ theme }) => theme.textInverted};
    }
`;

export const Messengers = styled.div<{ visible: boolean }>`
    position: fixed;
    bottom: ${rem(73)};
    background-color: ${({ theme }) => theme.backgroundModal};
    box-shadow: ${Variables.shadows.block};
    max-height: ${(p) => (p.visible ? rem(600) : 0)};
    width: ${rem(44)};
    border-radius: ${Variables.borderRadiusLg};
    transition:
        max-height 100ms,
        padding 100ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: ${(p) => (p.visible ? `${rem(20)}` : 0)} ${rem(16)};
    overflow: hidden;
    z-index: ${Variables.zIndexList.level5};

    svg {
        opacity: ${(p) => (p.visible ? 1 : 0)};
        transition: ${(p) => (p.visible ? 'opacity 150ms' : 'opacity 50ms')};
    }
`;

export const MessengerLink = styled.a<{ isPhone: boolean }>`
    text-decoration: none;
    cursor: pointer;
    margin-bottom: ${(p) => (p.isPhone ? 0 : rem(16))};
    ${(p) => (p.isPhone ? mediaHide(desktopHide, tabletHide) : '')};

    &:hover {
        transform: scale(1.1);
        transition: transform 100ms;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const MessengerButton = styled.div`
    text-decoration: none;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        transition: transform 100ms;
    }

    ${mobileHide};
`;

export const ChatWrapper = styled.div`
    position: relative;
    margin-left: auto;

    #mts-chat-react-main-window,
    #chat_shortcut {
        z-index: ${zIndex.level5};
    }
`;
