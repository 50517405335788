import React, {
    useState,
    ReactNode,
    FC,
    useContext,
    useEffect,
} from 'react';
import { MediaWidthEnum } from '../models/enums/media-width.enum';

interface DeviceContext {
    isMobile: boolean;
    isTablet: boolean;
    isMobileOrTablet: boolean;
    isDesktop: boolean;
}

export const DeviceContext = React.createContext<DeviceContext>({ isMobile: true, isTablet: false, isMobileOrTablet: false, isDesktop: false });

export const useDevice = () => useContext(DeviceContext);

interface DeviceContextProvider {
    value: 'mobile' | 'tablet' | 'desktop';
    children: ReactNode;
}

export const DeviceContextProvider: FC<DeviceContextProvider> = ({ value, children }) => {
    const [deviceType, setDeviceType] = useState(value);

    const getMatch = (media: string) => {
        return window.matchMedia(media).matches;
    };

    const getClientDevice = () => {
        if (getMatch(MediaWidthEnum.desktop)) {
            return 'desktop';
        }
        if (getMatch(MediaWidthEnum.tablet)) {
            return 'tablet';
        }

        return 'mobile';
    };

    const handleResize = () => {
        const clientDeviceType = getClientDevice();
        if (clientDeviceType !== deviceType) {
            setDeviceType(clientDeviceType);
        }
    };

    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [deviceType]);

    const isMobile = deviceType === 'mobile';
    const isTablet = deviceType === 'tablet';
    const isDesktop = deviceType === 'desktop';
    const isMobileOrTablet = isMobile || isTablet;

    return (
        <DeviceContext.Provider value={{ isMobile, isTablet, isDesktop, isMobileOrTablet }}>
            {children}
        </DeviceContext.Provider>
    );
};
