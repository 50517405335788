import * as React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import MainLanding from '../components/containers/main-landing';
import { SeoForHead } from '../components/containers/seo-for-head/seo-for-head';
import { getBrowserEnvs, ProcessEnv } from '../helpers/env.helper';
import { appendGeolocationSetCookie } from '../helpers/geolocation';
import { updateCurrentSegmentCode } from '../helpers/segment-code-cookie';
import { updEnv } from '../helpers/update-env';
import { withLogsServerSide } from '../helpers/with-logs-server-side';
import { SegmentsEnum } from '../models/enums/segments.enum';
import { Product } from '../models/product';
import { setCurrentProduct, setProposedArticles } from '../redux/article/article-actions';
import { resetServerStore } from '../redux/root-reducer';
import { setSegmentAction } from '../redux/segments/segments-actions';
import { nextWrapper } from '../redux/store';
import { SegmentsService } from '../services/segments.service';
import { getContextQueryParam } from '../helpers/context-query-params.helper';
import { proposedArticlesService } from '../services/proposed-articles.service';

interface HomePageProps {
    env: ProcessEnv;
}

const HomePage: NextPage<HomePageProps> = ({ env }) => {
    updEnv(env);

    return <>
        <SeoForHead
            title='МТС Поддержка - официальный сайт'
            descriptionContent='Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС'
        />
        <MainLanding />
    </>;
};

export const getServerSideProps: GetServerSideProps = withLogsServerSide(
    'index',
    nextWrapper.getServerSideProps((store) => async (ctx) => {
        updEnv();
        const cookies = await appendGeolocationSetCookie(ctx);
        const segmentData = await SegmentsService.getSegment(cookies, SegmentsEnum.individuals);

        updateCurrentSegmentCode(ctx, store, SegmentsEnum.individuals);

        store.dispatch(setSegmentAction(segmentData));
        store.dispatch(setCurrentProduct(new Product()));

        store.dispatch(resetServerStore());

        const [ utmContent ] = getContextQueryParam(ctx, 'utm_content');

        if (utmContent) {
            const proposedArticles = await proposedArticlesService.getProposedArticles(utmContent);
            store.dispatch(setProposedArticles(proposedArticles || []));
        }

        return {
            props: {
                env: getBrowserEnvs(process.env),
            },
        };
    }),
);

export default HomePage;
