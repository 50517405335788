import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isExactMatch } from '../helpers/reg-exp.helper';

const utmAppKeys = {
    utm_source: 'inhouse',
    utm_medium: 'mymts',
    //@todo для поддержки старых приложений. Почистить, когда все перейдут на app2
    utm_webview: 'app',
    //для новых приложений
    utm_webview_new: 'app2',
};

const checkUtm = (query: string = '') => {
    const source = `utm_source=${utmAppKeys.utm_source}`;
    const medium = `utm_medium=${utmAppKeys.utm_medium}`;
    const webview = `utm_medium=${utmAppKeys.utm_webview}`;
    const webviewNew = `utm_medium=${utmAppKeys.utm_webview_new}`;

    return query.includes(medium) && query.includes(source) || isExactMatch(query, webview) || isExactMatch(query, webviewNew);
};

export const useIsApp = () => {
    const router = useRouter();
    const query = router.asPath.split('?')[1];
    const [isApp, setIsApp] = useState(false);
    const [isMyMtsApp, setIsMyMtsApp] = useState(false);
    const [isWebviewApp, setIsWebviewApp] = useState(false);
    //для поддержки новых приложений
    const [isNewWebviewApp, setIsNewWebviewApp] = useState(false);

    useEffect(() => {
        const isUtm = checkUtm(query);
        setIsApp(isUtm);

        if (isUtm) {
            if (query.includes(utmAppKeys.utm_source) && query.includes(utmAppKeys.utm_medium)) {
                setIsMyMtsApp(true);
            } else if (isExactMatch(query, utmAppKeys.utm_webview)) {
                setIsWebviewApp(true);
            } else if (isExactMatch(query, utmAppKeys.utm_webview_new)) {
                setIsNewWebviewApp(true);
            }
        }
    }, [query]);

    return { isApp, isMyMtsApp, isWebviewApp, isNewWebviewApp };
};
