import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MessengerButton,
    MessengerLink,
    Messengers,
    StyledChatSimple,
    StyledChatToggle,
    StyledChatRound,
    StyledWrapper,
    StyledCloseIcon,
} from './chat-simple.styles';
import { RouterHelper } from '../../../helpers/router-helper';
import { ChatAnalytics } from '../../../helpers/web-analytics/chat-analytics';
import { getTypeChannel, getTypeWebAnalytics } from '../../../helpers/widget-chat';
// @ts-ignore
import cross from '../../../images/icons/cross.svg?next-images-ignore=true';
import { MessengerChannelInterface } from '../../../models/chat-simple';
import { ChannelTypeEnum } from '../../../models/enums/chat-simple.enum';
import { WebAnalyticsEventLabelsEnum } from '../../../models/enums/web-analytics.enum';
import { StateModel } from '../../../models/state.model';
import { selectCurrentArticle } from '../../../redux/article/article-selectors';
import { setModalVisible } from '../../../redux/chat/chat-actions';
import { B2cChatIcon } from '../../svg-components/chat/b2c-chat-icon';
import { TelephoneIcon } from '../../svg-components/chat/telephone';
import { Icon } from '../icon/icon';

interface ChatSimpleProps {
    messengerChannels: Array<MessengerChannelInterface>;
}

export const ChatSimple: FC<ChatSimpleProps> = ({ messengerChannels }) => {
    const dispatch = useDispatch();

    const isVisibleModalTelephone = useSelector<StateModel, boolean>((state) => state.chat.isVisibleModalTelephone);
    const article = useSelector(selectCurrentArticle);

    const articleIsPresent = RouterHelper.checkOnArticle();
    const productIsPresent = RouterHelper.checkOnProduct();

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const phone = messengerChannels.find((elem) => elem.type === ChannelTypeEnum.phone);

    const handleClickChat = (state: boolean) => {
        setIsOpened((prev) => !prev);

        const eventLabel = state ? WebAnalyticsEventLabelsEnum.hideChat : WebAnalyticsEventLabelsEnum.widgetChat;
        if (articleIsPresent) {
            ChatAnalytics.chatEvent(eventLabel, article);
        }
        if (productIsPresent) {
            ChatAnalytics.chatEvent(eventLabel);
        }
    };

    const handleClickMessenger = (type: string) => {
        ChatAnalytics.chatEvent(type);
    };

    const handleOpenTelephoneModal = () => {
        ChatAnalytics.chatEventActionShowElement(WebAnalyticsEventLabelsEnum.numberPhone);
        dispatch(setModalVisible(true));
    };

    useEffect(() => {
        if (isVisibleModalTelephone) {
            setIsOpened(false);
        }
    }, [isVisibleModalTelephone]);

    return (
        <StyledWrapper>
            <StyledChatSimple isOpened={isOpened}>
                <StyledChatToggle onClick={() => handleClickChat(isOpened)}>
                    <StyledChatRound className={isOpened ? 'active' : ''}>
                        {isOpened ? <StyledCloseIcon>
                            <Icon src={cross} width={24} height={24}/>
                        </StyledCloseIcon> : <B2cChatIcon />}
                    </StyledChatRound>
                </StyledChatToggle>
                <Messengers visible={isOpened}>
                    {messengerChannels &&
                        messengerChannels?.length > 0 &&
                        messengerChannels
                            .filter((elem) => elem.showChannel)
                            .map((elem, index) => (
                                <MessengerLink
                                    href={elem.type === ChannelTypeEnum.phone ? `tel:8${elem.link}` : elem.link}
                                    target={elem.activeLink ? '_blank' : '_self'}
                                    onClick={() => handleClickMessenger(getTypeWebAnalytics(elem.type))}
                                    isPhone={elem.type === ChannelTypeEnum.phone}
                                    key={index}
                                >
                                    {getTypeChannel(elem.type)}
                                </MessengerLink>
                            ))}
                    {phone && phone.showChannel && (
                        <MessengerButton onClick={handleOpenTelephoneModal}>
                            <TelephoneIcon />
                        </MessengerButton>
                    )}
                </Messengers>
            </StyledChatSimple>
        </StyledWrapper>
    );
};
