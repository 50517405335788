export enum WebAnalyticsEventCategoryEnum {
    menu = 'menu',
    crossElements = 'kross_elementy',
    authorization = 'avtorizaciya',
    home = 'glavnaya',
    registration = 'registraciya',
    documentation = 'documentaciya',
    communicator = 'kommunikator',
    mobileId = 'mobile_id',
    clickPayment = '1click_payment',
    cashback = 'cashback',
    ePay = 'epay',
    mtsAgents = 'mts_agenty',
    clients = 'klienty',
    clientConnection = 'podkluchenie_klienta',
    blog = 'blog',
    agentsSchool = 'shkola_agentov',
    main = 'main',
    error = 'error',
    search = 'poisk',
    chat = 'chat',
    video = 'video',
    article = 'article',
    geo = 'geo',
    group = 'group',
    unavailable = 'ne_dostupno',
    banner = 'banner',
    test = 'test',
    suggest = 'suggest',
    autofaq = 'autofaq',
    contacts = 'contacts',
    picture = 'picture',
    browserSearch = 'search',
}

export enum WebAnalyticsEventActionEnum {
    menuClick = 'menu_click',
    buttonClick = 'button_click',
    confirmed = 'confirmed',
    cardClick = 'card_click',
    formConfirmed = 'form_confirmed',
    formRejected = 'form_rejected',
    linkClick = 'link_click',
    socialClick = 'social_click',
    elementClick = 'element_click',
    appClick = 'app_click',
    cardOpen = 'card_open',
    error = 'error',
    submit = 'submit',
    elementOpen = 'element_open',
    filterAdd = 'filter_add',
    filterRemove = 'filter_remove',
    filterSet = 'filter_set',
    rejected = 'rejected',
    hideForm = 'hide_form',
    elementShow = 'element_show',
}

export enum WebAnalyticsPageTypeEnum {
    main = 'main',
    infoPage = 'infopage',
    searchPage = 'searchpage',
}

export enum WebAnalyticsActionGroupEnum {
    nonInteractions = 'non_interactions',
    interactions = 'interactions',
    conversions = 'conversions',
}

export enum WebAnalyticsEventEnum {
    pageView = 'mtsPageview',
    cross = 'mtsCross',
    login = 'mtsLogin',
    event = 'mtsEvent',
    vntCross = 'vntCross',
    vtnGeo = 'vtnGeo',
    vntGroup = 'vntGroup',
    vntLogin = 'vntLogin',
    vntEvent = 'vntEvent',
    vntMain = 'vntMain',
    vntSupport = 'vntSupport',
    vntPoisk = 'vntPoisk',
    vntChat = 'vntChat',
    vntVideo = 'vntVideo',
    scrn = 'scrn',
    error = 'error',
    vntBanner = 'vntBanner',
    vntTest = 'vntTest',
    vntSuggest = 'vntSuggest',
    vntAutofaq = 'vntAutofaq',
    vntContacts = 'vntContacts',
    vntPicture = 'vntPicture',
    vntSearch = 'vntSearch',
    vntArticle = 'vntArticle',
}

export enum WebAnalyticsTouchPointEnum {
    mobile = 'mobile',
    web = 'web',
    webview = 'webview',
}

export enum WebAnalyticsEventContentEnum {
    upBanner = 'up_banner',
    forClients = 'dlya_klientov',
    forStaff = 'dlya_sotrudnikov',
    forDevices = 'dlya_ustroistv',
    downPart = 'down_part',
    home = 'glavnaya',
    breadcrumbs = 'breadcrumbs',
    pageProduct = 'stranica_produkta',
    article = 'statya',
    //chat
    firstMessageInChat = 'pervoe_soobschenie_v_chate',
    numberPhone = 'nomer_telefona',
    link = 'ssylka',
    email = 'email',
    exceededSize = 'prevyshen_ves',
    nps = 'nps',
    fcr = 'fcr',
    operator = 'operator',
    client = 'polzovatel',
    file = 'fail',
    //pages
    mainB2CPage = 'main_b2c',
    mainB2BPage = 'main_b2b',
    mainB2PPage = 'main_b2p',
    productPage = 'productpage',
    articlePage = 'article',
    searchPage = 'searchpage',
    groupPage = 'grouppage',
    contactsB2C = 'contacts_b2c',
    contactsB2B = 'contacts_b2b',
}

export enum WebAnalyticsEventLabelsEnum {
    yes = 'da',
    no = 'net',
    articleImprovement = 'uluchshenie_stati',
    consultationRequest = 'zayavka_na_konsultaciu',
    connectionRequest = 'zayavka_na_podkluchenie',
    feedbackForm = 'forma_obratnoi_svyazi',
    signUp = 'zaregistriruisya',
    sendRequest = 'ostavit_zayavku',
    joinServiceRequest = 'zayavka_na_sovmestnyi_servis',
    moreDetails = 'podrobnee',
    toPlug = 'podkluchit',
    toContact = 'svyazatsya',
    newClient = 'novyi_klient',
    toMainPage = 'na_glavnuu',
    request = 'zayavka',
    questionsLeft = 'ostalis_voprosy',
    error = 'error',
    //new
    signIn = 'voity',
    logo = 'logo',
    search = 'poisk',
    authorizationSuccess = 'uspeshnaya_avtorizaciya',
    searchError = 'oshibka_poiska',
    searchNoResults = 'net_rezultatov',
    dislike = 'dislike',
    like = 'like',
    success = 'success',
    back = 'nazad',
    allServices = 'vse_servisy',
    questionWatch = 'posmotret_vopros',
    widgetChat = 'vidzhet_chata',
    telegram = 'telegram',
    whatsapp = 'whatsapp',
    hideChat = 'skryt_chat',
    more = 'esche',
    searchUsefulness = 'poleznost_poiska',
    numberPhone = 'nomer_telefona',
    phone = 'telefon',
    support = 'spravka',
    videoStart = 'nachalo_prosmotra',
    videoPause = 'video_ostanovleno',
    view15 = 'view_15',
    autoGeo = 'auto_geo',
    bannerGeo = 'banner_geo',
    changeGeo = 'change_geo',
    region = 'region',
    go = 'pereiti',
    cancel = 'otmena',
    regionLimited = 'ne_tot_region',
    noPage = 'net_stranicy',
    filters = 'filtry',
    bannerCallCenter = 'banner_call_center',
    phoneNumber = 'phone_number',
    abtest = 'abtest',
    topQuestion = 'top_question',
    widgetOpen = 'vidzhet_open',
    segment = 'segment',
    moi_mts = 'moi_mts',
    footer_button = 'footer_button',
    articlePicture = 'article_picture',
    browserSearch = 'browser_search',
    otherArticles = 'drugie_stati_v_razdele',
}

export enum WebAnalyticsNumberTypeEnum {
    virtual = 'virt',
    real = 'real',
    other = 'other',
}

export enum WebAnalyticsAccountTypeEnum {
    mobile = 'mobile',
    stv = 'stv',
    fix = 'fix',
    mgts = 'mgts',
    error = 'error',
}

export enum WebAnalyticsFilterEnum {
    product = 'product',
    statya = 'statya',
    b2c = 'b2c',
    b2b = 'b2b',
}

export enum CallCenterBannerContextEnum {
    stranicaStati = 'stranica_stati',
    stranicaProdukta = 'stranica_produkta',
}

export enum CallCenterBannerPhoneEnum {
    nomer1 = 'nomer_1',
    nomer2 = 'nomer_2',
}
