import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { desktop, media, tablet } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

export const FooterWrapper = styled.div`
    background-color: ${({ theme }) => theme.backgroundSecondary};
    z-index: ${Variables.zIndexList.level1};
    width: 100%;
`;

export const Footer = styled.div`
    display: flex;
    padding: ${rem(20)} 0;
    flex-direction: column;
    min-width: 360px;
    width: 100%;
    box-sizing: border-box;

    ${media(tablet, desktop)`
        height: ${rem(68)};
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: ${rem(42)};
        padding: 0;
    `};
`;

export const FooterLink = styled.a`
    color: ${({ theme }) => theme.textPrimaryLink};
    font-family: ${Variables.fonts.fontRegular};
    font-size: ${rem(17)};
    font-style: normal;
    font-weight: 400;
    line-height: ${rem(24)};
    margin-bottom: ${rem(16)};
    cursor: pointer;
    text-decoration: none;

    ${media(tablet, desktop)`
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        margin-bottom: 0;
    `};

    &:hover {
        text-decoration: underline 1px solid ${({ theme }) => theme.textPrimaryLinkHover};
    };
`;

export const FooterText = styled.p`
    color: ${({ theme }) => theme.textSecondary};
    font-family: ${Variables.fonts.fontRegular};
    font-size: ${rem(17)};
    font-style: normal;
    font-weight: 400;
    line-height: ${rem(24)};

    ${media(tablet, desktop)`
        font-size: ${rem(14)};
        line-height: ${rem(20)};
    `};
`;

export const FooterTextRight = styled.span`
    ${media(tablet, desktop)`
        color: ${({ theme }) => theme.textSecondary};
        font-family: ${Variables.fonts.fontRegular};
        font-style: normal;
        font-weight: 400;
        margin-left: auto;
        font-size: ${rem(14)};
        line-height: ${rem(20)};
    `};
`;
