import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { transliterate } from '../gtm.helper';
import { GtmService } from '../web-analytics.helpers';

export class PageAnalytics extends GtmService {
    static pageEvent(
        screenName: string,
        productName?: string | null,
        eventContent?: string,
    ) {
        this.pushPage({
            screenName,
            productName: transliterate(productName, true),
            eventContent,
        });
        this.log(this.pageEvent.name);
    }

    static browserSearchEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntSearch,
            eventCategory: WebAnalyticsEventCategoryEnum.browserSearch,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.browserSearch,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        });

        this.log(this.browserSearchEvent.name);
    }
}
